import { Link } from "gatsby"
import React, { useContext } from "react"
import ThemeContext, { Themes } from "src/theme/context"
import * as styles from "./Footer.module.scss"

const getGridClass = theme => {
  switch (theme) {
    case Themes.secondary:
    case Themes.dark:
    case Themes.secondaryDark:
      return styles.GridDarkTheme
    default:
      return styles.GridDefaultTheme
  }
}

export function Footer({ footerTheme = "", footerStyles = {} }) {
  const { theme } = useContext(ThemeContext)

  const gridClass = getGridClass(footerTheme || theme)

  const shouldInvertColors = gridClass === styles.GridDefaultTheme

  const blesuLogoStyles = { filter: `invert(${shouldInvertColors ? 1 : 0})` }

  return (
    <footer className={styles.FooterWrapper} style={footerStyles}>
      <div className={gridClass}>
        <div className={styles.GridItem} style={{ gridArea: "a" }}>
          <img
            style={blesuLogoStyles}
            src="/blesutext.png"
            alt="small blesu logo"
            width="97"
            height="18"
          />
          <ul>
            <li style={{ textDecoration: "none" }}>REGON: 368624511</li>
            <li style={{ textDecoration: "none" }}>NIP: 1182156865</li>
            <li style={{ textDecoration: "none" }}>MAIL: hello@blesu.com</li>
          </ul>
        </div>

        <div className={styles.GridItem} style={{ gridArea: "b" }}>
          <p>Office</p>
          <ul>
            <li>WeWork</li>
            <li>Mennica Legacy Tower</li>
            <li>Prosta 20</li>
            <li>00-850, Warsaw</li>
          </ul>
        </div>

        {/* <div className={styles.GridItem} style={{ gridArea: "c" }}>
          <p>Language</p>
          <ul>
            <li>Polish</li>
            <li>English</li>
          </ul>
        </div> */}

        <div className={styles.GridItem} style={{ gridArea: "d" }}>
          <p>Sitemap</p>
          <ul>
            <li>
              <Link to="/services" className={styles.LinkButton} state={{}}>
                What we do
              </Link>
            </li>
            <li>
              <Link to="/process" className={styles.LinkButton} state={{}}>
                How we do it
              </Link>
            </li>

            <li>
              <Link
                to="/"
                className={styles.LinkButton}
                state={{ scrollTo: "projects-section" }}
              >
                Our projects
              </Link>
            </li>

            {/* <li>Career</li> */}
            <li>
              <Link to="/contact" className={styles.LinkButton}>
                Contact
              </Link>
            </li>

            <li>
              <Link to="/privacy-policy" className={styles.LinkButton}>
                Privacy policy
              </Link>
            </li>
          </ul>
        </div>

        <div className={styles.GridItem} style={{ gridArea: "e" }}>
          <p>Services</p>
          <ul>
            <li>IT business analysis</li>
            <li>Product design</li>
            <li>Web development</li>
            <li>Mobile application</li>
            <li>Blockchain development</li>
            <li>Digital transformation</li>
          </ul>
        </div>
      </div>
      <div className={styles.CopyrightContainer}>
        <span>Copyright © Blesu 2022</span>
        <span>Created by BLESU for BLESU😎</span>
      </div>
    </footer>
  )
}
