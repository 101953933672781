import React from "react"
import PropTypes from "prop-types"
import Footer from "../Footer"

import "./Layout.scss"

import ThemeContext from "src/theme/context"

export const Layout = ({ children, footerTheme, footerStyles }) => {
  const { theme } = React.useContext(ThemeContext)

  return (
    <>
      <main className={`theme-${theme}`}>{children}</main>
      <Footer footerTheme={footerTheme} footerStyles={footerStyles} />
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}
